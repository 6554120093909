<template>
    <div class="page">
        <el-form size="small" :inline="true" class="query-form">
            <el-form-item>
                <el-input size="small" v-model="srhStr" placeholder="请输入搜索关键词" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="search()" size="small" icon="el-icon-search">查询</el-button>
            </el-form-item>
        </el-form>
        <div class="bg-white top">
            <div class="flex_l_t tagBox b_b">
                <div class="tagTit">资源标签：</div>
                <div class="flex1 tagItem" :style="{height: tagUnfoldFlag ? 'auto': '28px'}">
                    <el-button :type="tagIndex == index ? 'primary': 'text'" round size="mini"
                               v-for="(item, index) in tag" :key="index" @click="tagSel(item, index, 0)">{{item.labelName}}
                    </el-button>
                </div>
                <el-button class="operation" type="text" @click="tagUnfold(0)">{{tagUnfoldFlag ? '收起' : '展开'}} <span
                        :class="tagUnfoldFlag ? 'el-icon-caret-top': 'el-icon-caret-bottom'"></span></el-button>
            </div>
            <div class="flex_l_t tagBox b_b">
                <div class="tagTit">资源分组：</div>
                <div class="flex1 tagItem" :style="{height: groupsUnfoldFlag ? 'auto': '28px'}">
                    <el-button :type="groupsIndex == index ? 'primary': 'text'" round size="mini"
                               v-for="(item, index) in groups" :key="index" @click="tagSel(item, index, 1)">{{item.groupName}}
                    </el-button>
                </div>
                <el-button class="operation" type="text" @click="tagUnfold(1)">{{groupsUnfoldFlag ? '收起' : '展开'}} <span
                        :class="groupsUnfoldFlag ? 'el-icon-caret-top': 'el-icon-caret-bottom'"></span></el-button>
            </div>
            <div class="flex_l_t tagBox b_b">
                <div class="tagTit">资源分类：</div>
                <div class="flex1 tagItem" :style="{height: classifyUnfoldFlag ? 'auto': '28px'}">
                    <el-button :type="classifyIndex == index ? 'primary': 'text'" round size="mini"
                               v-for="(item, index) in dicts" :key="index" @click="tagSel(item, index, 2)">{{item.label}}
                    </el-button>
                </div>
                <el-button class="operation" type="text" @click="tagUnfold(2)">{{classifyUnfoldFlag ? '收起' : '展开'}}
                    <span :class="classifyUnfoldFlag ? 'el-icon-caret-top': 'el-icon-caret-bottom'"></span></el-button>
            </div>
            <div class="flex_l_t tagBox b_b" v-if="selectTags.length > 0">
                <div class="tagTit">已选条件：</div>
                <div class="flex1">
                    <el-tag size="small" v-for="(item, index) in selectTags" :key="index" closable class="m_r1" closable
                            :disable-transitions="false"
                            @close="handleClose(item)">
                        {{item.tag.labelName ? item.tag.labelName : item.tag.groupName ?item.tag.groupName : item.tag.label}}
                    </el-tag>
                    <el-button type="text" size="mini" @click="handleCloseAll">清除筛选</el-button>
                </div>
            </div>
            <el-row :gutter="12" v-loading="listLoading" class="itemInfo2 itemInfo">
                <div v-if="dataList.length==0 && !listLoading" style="height: calc(100vh - 490px); line-height: calc(100vh - 490px);text-align: center">暂无数据</div>
                <el-col :span="8" v-for="(item, index) in dataList" :key="item.id" class="m_t2 pointer">
                    <el-card shadow="hover" @click.native="toClassificationDetails(item)">
                        <el-image fit="cover" :src="item.thumb_path" class="infoImg">
                            <div slot="error">
                                <el-image :src="require('@/assets/img/thumbnail.png')" class="infoImg"></el-image>
                            </div>
                        </el-image>
                        <div class="text_center omit f_w tit"  style="height: 18px">{{item.resource_name?item.resource_name:item.resourceName}}</div>
                        <div class="flex_b_c">
                            <span>{{$dictUtils.getDictLabel("resource_type",item.resourceType,'-')}}</span>
                            <span>{{item.onCollection==0 ? '非藏品':'藏品'}}</span>
                        </div>
                        <div>{{item.writtenTime}}</div>
                    </el-card>
                </el-col>
            </el-row>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageNo"
                    :page-sizes="[15, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <!--查看-->
        <ClassificationDetails ref="classificationDetails"></ClassificationDetails>
    </div>
</template>

<script>
    import ClassificationDetails from './classificationDetails' // 查看弹窗

    export default {
        components: {ClassificationDetails},
        data() {
            return {
                type1:'',
                type2:'',
                type3:'',
                dicts:[],
                srhStr: '',
                tag: [],
                tagIndex: 0,
                tagUnfoldFlag: false,
                groups: [],
                groupsIndex: 0,
                groupsUnfoldFlag: false,
                classify: [],
                // classify:[],
                classifyIndex: 0,
                classifyUnfoldFlag: false,
                selectTags: [],
                dataList: [],
                pageNo: 0,
                pageSize: 15,
                total: 0,
                listLoading:false,
            }
        },
        mounted() {
            this.groupList();
            this.dictList();
            this.labelList();
        },
        methods: {
            search(){
                this.pageNo=1
                this.refreshList()
            },
            refreshList() {
                this.listLoading = true
                this.$axios(this.api.resources.queryResourceList, {
                    'current':this.pageNo,
                    'size':this.pageSize,
                    'labelId': this.selectTags[0].tag.id,
                    'groupId': this.selectTags[1].tag.id,
                    'resourceType': this.selectTags[2].tag.id,
                    'name':this.srhStr
                }, 'get').then(res => {
                    if (res.status) {
                        this.listLoading = false
                        this.dataList=res.data.records;
                        this.total=parseInt(res.data.total);
                    }
                })

            },
            //资源标签
            labelList(){
                this.$axios(this.api.resources.queryLabelList, {}, 'get').then(res => {
                    if (res.status) {
                        this.tag = res.data
                        this.tag.unshift({labelName: "全部",id:""})
                        this.type1=true;
                        this.tagSel(this.tag[0], 0, 0,1)
                    }
                })
            },
            //资源分组
            groupList(){
                this.$axios(this.api.resources.getAllGroupName, {}, 'get').then(res => {
                    if (res.status) {
                        this.groups = res.data
                        this.groups.unshift({groupName: "全部",id:""})
                        this.type2=true;
                        this.tagSel(this.groups[0], 0, 1,1)
                    }
                })
            },
            //资源分类
            dictList(){
                this.$axios(this.api.resources.queryByIdDictTypeValue, {'type':'resource_type'}, 'get').then(res => {
                    if (res.status) {
                        this.dicts = res.data
                        this.dicts.unshift({label: "全部",id:""})
                        this.type3=true;
                        this.tagSel(this.dicts[0], 0, 2,1)
                    }
                })
            },
            //选择条件 type: 0标签；1分组；2分类
            //num 区分是否用来默认选项  默认第一项
            tagSel(item, index, type,num) {
                this.pageNo=0
                this.total=0
                if (type == 0) {
                    this.tagIndex = index
                } else if (type == 1) {
                    this.groupsIndex = index
                } else if (type == 2) {
                    this.classifyIndex = index
                }
                this.selectTags = [{
                    type: 0,
                    tag: this.tag[this.tagIndex]
                },{
                    type: 1,
                    tag: this.groups[this.groupsIndex]
                },{
                    type: 2,
                    tag: this.dicts[this.classifyIndex]
                }]
                if (num==1){
                    if (this.type1 && this.type2 && this.type3){
                        this.refreshList();
                    }
                }else {
                    this.refreshList();
                }


            },
            //展开 type: 0标签；1分组；2分类
            tagUnfold(type) {
                if (type == 0) {
                    this.tagUnfoldFlag = !this.tagUnfoldFlag
                } else if (type == 1) {
                    this.groupsUnfoldFlag = !this.groupsUnfoldFlag
                } else if (type == 2) {
                    this.classifyUnfoldFlag = !this.classifyUnfoldFlag
                }
            },
            // 关闭标签
            handleClose(val) {
                if (val.type == 0) {
                    this.tagIndex = 0
                } else if (val.type == 1) {
                    this.groupsIndex = 0
                } else if (val.type == 2) {
                    this.classifyIndex = 0
                }
                this.selectTags = [{
                    type: 0,
                    tag: this.tag[this.tagIndex]
                },{
                    type: 1,
                    tag: this.groups[this.groupsIndex]
                },{
                    type: 2,
                    tag: this.dicts[this.classifyIndex]
                }]
                this.refreshList();
            },
            // 清除筛选
            handleCloseAll() {
                this.tagIndex = 0
                this.groupsIndex = 0
                this.classifyIndex = 0
                this.selectTags = [{
                    type: 0,
                    tag: this.tag[this.tagIndex]
                },{
                    type: 1,
                    tag: this.groups[this.groupsIndex]
                },{
                    type: 2,
                    tag: this.dicts[this.classifyIndex]
                }]
               this.refreshList();
            },
            // 详情
            toClassificationDetails(row) {
                this.$refs.classificationDetails.init(row.id,row.resourceType);
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                this.refreshList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.refreshList()
            },
        }
    }
</script>

<style scoped>
    #main .el-main-center .mainCenter .bg-white {
        overflow-y: auto;
    }
    .tagBox {
        color: #999999;
        font-size: 12px;
        padding-top: 10px;
        padding-bottom: 5px;
    }

    .tagItem {
        height: 28px;
        overflow: hidden;
    }

    .tagTit {
        padding-top: 4px;
    }

    .tagItem >>> .el-button--text {
        color: #333333 !important;
    }

    .el-button + .el-button {
        margin-left: 0 !important;
    }

    .operation, .itemInfo {
        font-size: 12px;
    }

    .itemInfo2{
        min-height: calc(100vh - 500px);
    }

    .infoImg {
        width: 100%;
        height: 200px;
    }

    .itemInfo .tit {
        font-size: 14px;
        margin-bottom: 6px;
    }

    ::-webkit-scrollbar {
        display: none;
    }
</style>
